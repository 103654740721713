.App {
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  margin: 0;
  height: 100%;
  background-color: white;
  width: 100%;
}


