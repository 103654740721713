.fg--search {
    background: transparent;
    position: relative;
    width: 300px;
  }
  
  .fg--search input {
    width: 100%;
    padding: 10px 0px 10px 50px;
    display: block;
    font-size: 20px;
    font-weight: lighter;
    border-radius: 35px;
    border-color: transparent;
    background: rgba(217, 217, 217, 1);
  }

  .fg--search input:focus {
    outline: none;
  }
  
.fg--search .fa-search-icon {
    background: transparent;
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: inline-block;
    font-size: 20px;
    position: absolute;
    top: 0;
    left: 0;
    padding: 18px 20px;
    z-index: 2;
}