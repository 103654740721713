.buttonTitle {
    color: black;
    font-weight: lighter;
    height: 100%;
    padding: 15px;
    margin: 0 10px 20px 10px;
    width: 150px;
}

.dropdown {
    text-align: left;
    border-color: white;
}

.exportButton:hover {
    background-color: lightgray;
    font-family: Arial, Helvetica, sans-serif;
}

.exportButton {
    /* background-color: rgb(37, 223, 223); */
    background-color: whitesmoke;
    border: 2px solid #25518f;
    box-shadow: 0 6px  #25518f;
    color: #183153;
    font-weight: bold;
}


div.menu-container {
	width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap-reverse;
}

.menu-item {
	float: left;
    position: relative;
    width: 100%;
}

.menu-item ul {
	margin-top: 10px;
	list-style-type: none;
	height: 0px;
	overflow-y: scroll;
    position: absolute;
    top: 100%;
    width: 100%;
    overflow-x: hidden;
    z-index: 200;
    background-color: white;
	
	-webkit-transition: height .5s ease;
    -moz-transition: height .5s ease;
    -o-transition: height .5s ease;
    -ms-transition: height .5s ease;
    transition: height .5s ease;
}

.menu-item:hover ul {
	height: 200px;
}

.menu-item ul li {
	font-size: 16px;
	width: 100%;
	margin: 0;
	border-radius: 5px;
	margin-top: 5px;
    padding: 5px;
    overflow-x: hidden;
    text-align: left;
}

.menu-item ul li:hover {
    cursor: pointer;
}

.option-item {
    background-color: rgba(229, 229, 229, 1);
    border-radius: 20px;
    margin: 10px;
    width: 100px;
    text-align: center;
    display: flex;
    justify-content: center;
    font-weight: bolder;
}

.option-item:hover {
    cursor: pointer;
    background-color: rgba(217, 217, 217, 1) !important;
}

.page-button:hover {
    cursor: pointer;
    background-color: rgba(217, 217, 217, 1) !important;
}

.select-search-option:hover{
    background-color: rgba(217, 217, 217, 0.75);
    color:black
}


.timeButton {
    color: black;
    width: 55px;
    height: 10px;
    font-size: small;
}

.timeButton:hover {
    cursor: pointer;
    color: whitesmoke;
    font-weight: bold;
}


th {
    padding: 10px 20px;
}

tr {
    padding: 0px, 0px;
    width: 100%;
}

.trData {
    color: #05152c
}

.submitIcon {
    color: gray;
    padding: 5px;

}

.submitIcon:hover {
    color: #25518f;
    cursor: pointer;
    background-color: lightgray;
    border-radius: 5px;
}

.eyeIcon:hover {
    color: lightgray;
    cursor: pointer;
}
.X {
    color: gray;
}
.X:hover {
    cursor: pointer;
    color: black;
}