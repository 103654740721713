$btn_indigo: #3f51b5;

.rkmd-btn {
    display: inline-block;
    position: relative;
    cursor: pointer;
    height: 50px;
    line-height: 35px;
    padding: 0 1.5rem;
    color: #424242;
    font-size: 15px;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
    letter-spacing: .8px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    vertical-align: middle;
    white-space: nowrap;
    outline: none;
    border: none;
    user-select: none;
    border-radius: 2px;
    transition: all .3s ease-out;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    color: #FFF;
    background-color: $btn_indigo;
    &:hover { background-color: lighten($btn_indigo, 8%); }
}
