 .trending-box {
    width: 410px;
    height: 145px;
    background-color: rgba(217, 217, 217, 1);
    border-radius: 20px;
    margin: 50px 20px 0px 20px;
    display: flex;
}

.trending-box-index {
    margin-top: 25px;
    margin-left: 10px;
    font-size: 55px;
    width: 106px;
    height: 106px;
}

.trending-box-text {
    display: flex;
    flex-direction: column;
    margin-left: 15px;
    margin-top: 20px;
    justify-content: flex-start;
    align-items: flex-start;
    line-height: 32px;

}

.trending-box-hashtag {
    font-weight: bold;
}

